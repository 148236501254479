import React, { useState, useEffect } from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { doc, setDoc } from 'firebase/firestore';
import db from '../config/firebase_config';
import { scaleLinear } from 'd3-scale';
import FeedbackDialog from './FeedbackDialog';
import {
  calculateRMSSD,
  calculateSDNN,
  calculateStressIndex,
  calculateHeartRate,
  calculateReadiness,
  calculateSD1SD2Ratio,
} from './MetricsCalculator';
import BluetoothConnectModal from './BluetoothConnectModal';
import  {StoreData, RRData }  from './StoreData';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';

import {
  Box,
  Button,
  Container,
  Typography,
  Paper,
  Grid,
  useMediaQuery,
  useTheme,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import FeedbackIcon from '@mui/icons-material/Feedback';
import InfoIcon from '@mui/icons-material/Info';
import { SelectChangeEvent } from '@mui/material/Select';

const SERVICE_UUID = 0x180D; // Heart Rate Service
const CHARACTERISTIC_UUID = 0x2A37; // Heart Rate Measurement Characteristic // Standard Heart Rate Measurement Characteristic UUID

interface Metrics {
  rmssd: number | null;
  sdnn: number | null;
  stressIndex: number | null;
  heartRate: number | null;
  readiness: number | null;
  sd1sd2Ratio: number | null;
}

interface MetricsHistory {
  timestamp: number;
  rmssd: number | null;
  sdnn: number | null;
  stressIndex: number | null;
  heartRate: number | null;
  readiness: number | null;
  sd1sd2Ratio: number | null;
}

const HeartRateMonitor: React.FC = () => {
  const [device, setDevice] = useState<BluetoothDevice | null>(null);
  const [server, setServer] = useState<BluetoothRemoteGATTServer | null>(null);
  const [hrCharacteristic, setHrCharacteristic] = useState<BluetoothRemoteGATTCharacteristic | null>(null);
  const [rrIntervals, setRrIntervals] = useState<number[]>([]);
  const [rrIntervalData, setRrIntervalData] = useState<RRData[]>([]);
  const [isBluetoothModalOpen, setBluetoothModalOpen] = useState(false);
  const [metrics, setMetrics] = useState<Metrics>({
    rmssd: null,
    sdnn: null,
    stressIndex: null,
    heartRate: null,
    readiness: null,
    sd1sd2Ratio: null,
  });
  const [metricsHistory, setMetricsHistory] = useState<MetricsHistory[]>([]);
  const [connectionStatus, setConnectionStatus] = useState<string>('Not Connected');
  const [feedbackOpen, setFeedbackOpen] = useState<boolean>(false);
  const [selectedMetrics, setSelectedMetrics] = useState<string[]>(['stressIndex', 'readiness']);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (device) {
      const handleDisconnection = () => {
        console.log('Device disconnected');
        setConnectionStatus('Disconnected');
        setDevice(null);
        setServer(null);
        setHrCharacteristic(null);
      };

      device.addEventListener('gattserverdisconnected', handleDisconnection);

      return () => {
        device.removeEventListener('gattserverdisconnected', handleDisconnection);
      };
    }
  }, [device]);

  const connectToDevice = async (): Promise<void> => {
    try {
      if (device) {
        await device.gatt?.disconnect();
      }

      await addDoc(collection(db, 'events'), {
        id: 'attempting_to_connect_to_device' + device?.name + device?.id,
        event: 'Attempting to connect to device',
        timestamp: serverTimestamp()
      });

      const options: RequestDeviceOptions = {
        filters: [
          { services: [SERVICE_UUID] }  // Be more specific instead of acceptAllDevices
        ],
      };

      console.log('Starting device connection process...');
      const newDevice = await navigator.bluetooth.requestDevice(options);
      console.log('Device selected:', newDevice.name);

      const newServer = await newDevice.gatt?.connect();
      console.log('GATT server connected:', newServer ? 'success' : 'failed');

      if (!newServer) {
        throw new Error('Failed to connect to GATT server');
      }

      const service = await newServer.getPrimaryService(SERVICE_UUID);
      console.log('Heart Rate Service found:', SERVICE_UUID);

      const characteristic = await service.getCharacteristic(CHARACTERISTIC_UUID);
      console.log('Heart Rate Characteristic found:', CHARACTERISTIC_UUID);

      setDevice(newDevice);
      setServer(newServer);
      setHrCharacteristic(characteristic);
      setConnectionStatus(`Connected to ${newDevice.name || 'unknown device'}`);
      await addDoc(collection(db, 'events'), {
        id: 'connected_to_device' + newDevice.name + newDevice.id,
        event: `Connected to device: ${newDevice.name || 'unknown'}`,
        timestamp: serverTimestamp()
      });
    } catch (error) {
      console.error('Detailed connection error:', error);
      setConnectionStatus('Connection failed');
      await addDoc(collection(db, 'events'), {
        id: 'connection_failed' + device?.name + device?.id,
        event: `Connection failed: ${error}`,
        timestamp: serverTimestamp()
      });
    }
  };

  const formatTime = (tick: number): string => {
    const date = new Date(tick);
    return date.toLocaleTimeString();
  };

  useEffect(() => {
    if (hrCharacteristic) {
      console.log('Setting up characteristic notifications...');
      
      const handleCharacteristicValueChanged = (event: Event) => {
        console.log('Received characteristic value change event', event);
        const value = (event.target as BluetoothRemoteGATTCharacteristic).value!;
        processHRVData(value);
      };

      hrCharacteristic.addEventListener('characteristicvaluechanged', handleCharacteristicValueChanged);
      
      hrCharacteristic.startNotifications()
        .then(() => {
          console.log('Successfully started notifications');
        })
        .catch(error => {
          console.error('Failed to start notifications:', error);
        });

      return () => {
        console.log('Cleaning up characteristic notifications...');
        hrCharacteristic.removeEventListener('characteristicvaluechanged', handleCharacteristicValueChanged);
        hrCharacteristic.stopNotifications()
          .then(() => console.log('Notifications stopped'))
          .catch(error => console.error('Error stopping notifications:', error));
      };
    }
  }, [hrCharacteristic]);

  function getCurrentTimestamp() {
    const now = new Date();
  
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
  
    return `${year}-${month}-${day}_${hours}:${minutes}:${seconds}`;
  }

  const processHRVData = (value: DataView) => {
    const flags = value.getUint8(0);
    console.log("HRV Data: ", value.buffer);
    console.log('Received HRV data. Flags:', flags.toString(2));
    let index = 1;
    let heartRate: number;

    if (flags & 0x01) {
      heartRate = value.getUint16(index, true);
      index += 2;
    } else {
      heartRate = value.getUint8(index);
      index += 1;
    }
    console.log('Processed heart rate:', heartRate);

    if (flags & 0x10 || true) {
      console.log('RR intervals present in data');
      const rrIntervalsTemp: number[] = [];
      while (index + 1 < value.byteLength) {
        const rrInterval = value.getUint16(index, true) / 1024;
        index += 2;
        if (rrInterval >= 0.3 && rrInterval <= 2.0) {
          rrIntervalsTemp.push(rrInterval);
        }
      }
      if (rrIntervalsTemp.length === 0) {
        console.log('No valid RR intervals found, adding single RR interval');
        rrIntervalsTemp.push(value.getUint16(0, true) / 1024);
      }
      console.log('Processed RR intervals:', rrIntervalsTemp);

      for (let rrInterval of rrIntervalsTemp) {
        const rrData: RRData = {
          timestamp: getCurrentTimestamp(),
          rrInterval: Math.round(rrInterval * 1000), // RR interval in milliseconds
        };
        setRrIntervalData((prevData) => [...prevData, rrData]);
      }

      setRrIntervals((prevIntervals) => {
        const updatedIntervals = [...prevIntervals, ...rrIntervalsTemp];
        return updatedIntervals.slice(-300);

      });
    } else {
      console.log('No RR intervals in this data packet');
    }
  };

  useEffect(() => {
    if (rrIntervals.length > 15) {
      console.log('Calculating metrics with', rrIntervals.length, 'RR intervals');
      calculateMetrics();
      setConnectionStatus('Recording ' + device?.name);
    } else if (device) {
      console.log('Waiting for more RR intervals:', rrIntervals.length);
      setConnectionStatus('Calibrating ' + device.name);
    }
  }, [rrIntervals]);

  const calculateMetrics = () => {
    const rmssd = calculateRMSSD(rrIntervals);
    const sdnn = calculateSDNN(rrIntervals);
    const stressIndex = calculateStressIndex(rrIntervals);
    const heartRate = calculateHeartRate(rrIntervals);
    const readiness = calculateReadiness(stressIndex, heartRate);
    const sd1sd2Ratio = calculateSD1SD2Ratio(rrIntervals);

    const stressIndexScale = scaleLinear().domain([0, 20]).range([0, 100]);
    const readinessScale = scaleLinear().domain([0, 100]).range([0, 100]);
    const sd1sd2RatioScale = scaleLinear().domain([0, 10]).range([0, 100]);

    const normalizedMetrics: Metrics = {
      rmssd: rmssd ?? 0, // Default to 0 if rmssd is null or undefined
      sdnn: sdnn ?? 0,
      stressIndex: Math.min(stressIndexScale(stressIndex ?? 0), 100),
      heartRate: heartRate ?? 0,
      readiness: readinessScale(readiness ?? 0),
      sd1sd2Ratio: Math.min(sd1sd2RatioScale(sd1sd2Ratio ?? 0), 100),
    };
    

    setMetrics(normalizedMetrics);

    const timestamp = Date.now();
    setMetricsHistory((prevHistory) => {
      const newHistory: MetricsHistory[] = [
        ...prevHistory,
        { timestamp, ...normalizedMetrics },
      ];

      const fiveMinutesAgo = timestamp - 5 * 60 * 1000;
      return newHistory.filter((dataPoint) => dataPoint.timestamp >= fiveMinutesAgo);
    });
  };

  const handleFeedbackOpen = () => {
    setFeedbackOpen(true);
  };

  const handleFeedbackClose = () => {
    setFeedbackOpen(false);
  };

  const handleMetricSelection = (event: SelectChangeEvent<string[]>) => {
    setSelectedMetrics(event.target.value as string[]);
  };

  useEffect(() => {
    return () => {
      // Cleanup when component unmounts
      if (device?.gatt?.connected) {
        device.gatt.disconnect();
      }
      setDevice(null);
      setServer(null);
      setHrCharacteristic(null);
      setRrIntervals([]);
      setConnectionStatus('Not Connected');
    };
  }, []);

  return (
    <div className="container">
      <Container maxWidth="md">
        <Box sx={{ mt: 4, mb: 4 }}>
          <Typography variant={isMobile ? 'h5' : 'h4'} align="center" gutterBottom>
            Heart Rate Monitor
          </Typography>
          <Typography variant="body1" align="center" gutterBottom>
            We support most wearables compatible with the Web Bluetooth API. Try it now!
          </Typography>
          <Grid container spacing={2} justifyContent="center">
            <Grid item>
              <Button variant="contained" color="primary" onClick={connectToDevice}>
                Connect to Device
              </Button>
            </Grid>
            <Grid item>
              <Button variant="outlined" startIcon={<FeedbackIcon />} onClick={handleFeedbackOpen}>
                Feedback
              </Button>
            </Grid>
            <Grid item>
              <Button variant="outlined" startIcon={<InfoIcon />} onClick={() => setBluetoothModalOpen(true)}>
                Instructions
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" gutterBottom>
            Select Metrics to Track:
          </Typography>
          <FormControl fullWidth>
            <InputLabel id="metrics-select-label">Metrics</InputLabel>
            <Select
              labelId="metrics-select-label"
              multiple
              value={selectedMetrics}
              onChange={handleMetricSelection}
              renderValue={(selected) => (selected as string[]).join(', ')}
            >
              <MenuItem value="stressIndex">Stress Index</MenuItem>
              <MenuItem value="readiness">Readiness</MenuItem>
              <MenuItem value="cognitiveFatigue">Cognitive Fatigue</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <BluetoothConnectModal
          isOpen={isBluetoothModalOpen}
          onClose={() => setBluetoothModalOpen(false)}
          onConnect={connectToDevice}
        />
        <FeedbackDialog open={feedbackOpen} onClose={handleFeedbackClose} />
        <Paper elevation={3} sx={{ p: 2, mb: 4 }}>
          <Typography variant="h6" gutterBottom>
            Metrics:
          </Typography>
          <Typography variant="body1" gutterBottom>
            <strong>Connection Status:</strong> {connectionStatus}
          </Typography>
          <Grid container spacing={2}>
            {selectedMetrics.includes('stressIndex') && (
              <Grid item xs={6} sm={4}>
                <Typography variant="body1">
                  <strong>Stress Index:</strong> {metrics.stressIndex?.toFixed(2) || 'N/A'}
                </Typography>
              </Grid>
            )}
            {selectedMetrics.includes('readiness') && (
              <Grid item xs={6} sm={4}>
                <Typography variant="body1">
                  <strong>Readiness:</strong> {metrics.readiness?.toFixed(2) || 'N/A'}
                </Typography>
              </Grid>
            )}
            {selectedMetrics.includes('cognitiveFatigue') && (
              <Grid item xs={6} sm={4}>
                <Typography variant="body1">
                  <strong>Cognitive Fatigue:</strong> {/* Add cognitive fatigue calculation here */}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Paper>
        <Paper elevation={3} sx={{ p: 2 }}>
          <Typography variant="h6" gutterBottom>
            5-Minute Metrics Trend
          </Typography>
          <Box sx={{ overflowX: 'auto' }}>
            <ResponsiveContainer width="100%" height={isMobile ? 300 : 400}>
              <AreaChart
                data={metricsHistory}
                margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
              >
                <defs>
                  <linearGradient id="colorStressIndex" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#ffc658" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#ffc658" stopOpacity={0} />
                  </linearGradient>
                  <linearGradient id="colorReadiness" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#0088FE" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#0088FE" stopOpacity={0} />
                  </linearGradient>
                  <linearGradient id="colorSd1sd2Ratio" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                  </linearGradient>
                </defs>
                <XAxis
                  dataKey="timestamp"
                  tickFormatter={formatTime}
                  type="number"
                  domain={['auto', Date.now() + 5 * 60 * 1000]}
                />
                <YAxis />
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip labelFormatter={formatTime} />
                <Legend />
                <Area
                  type="monotone"
                  dataKey="stressIndex"
                  stroke="#ffc658"
                  fillOpacity={1}
                  fill="url(#colorStressIndex)"
                  connectNulls={true}
                />
                <Area
                  type="monotone"
                  dataKey="readiness"
                  stroke="#0088FE"
                  fillOpacity={1}
                  fill="url(#colorReadiness)"
                  connectNulls={true}
                />
                <Area
                  type="monotone"
                  dataKey="sd1sd2Ratio"
                  stroke="#82ca9d"
                  fillOpacity={1}
                  fill="url(#colorSd1sd2Ratio)"
                  connectNulls={true}
                />
              </AreaChart>
            </ResponsiveContainer>
          </Box>
        </Paper>
        <StoreData rrIntervalData={rrIntervalData} />
      </Container>
    </div>
  );
};

export default HeartRateMonitor;
